const app = {
  init: function () {
    this.initNav();
    this.initVideos();
    this.initLightbox();
  },

  initLightbox: function () {
    lightbox.option({
      resizeDuration: 300,
      fadeDuration: 300,
      imageFadeDuration: 250,
      wrapAround: true,
      albumLabel: "",
      disableScrolling: true,
    });

    // find all wp block galleries
    const galleries = Array.from(
      document.querySelectorAll(".wp-block-gallery")
    );

    const wooGalleries = Array.from(
      document.querySelectorAll(".woocommerce-product-gallery")
    );

    const singleImages = Array.from(
      document.querySelectorAll(".wp-block-image a")
    );

    if (galleries) {
      // loop over every gallery
      for (let i = 0; i < galleries.length; i++) {
        let galleryID = "gallery" + i;

        // find item links in gallery
        const galleryItems = Array.from(
          galleries[i].querySelectorAll(".blocks-gallery-item a")
        );

        if (galleryItems) {
          // loop over gallery links in gallery
          for (let j = 0; j < galleryItems.length; j++) {
            // set gallery id to link
            galleryItems[j].setAttribute("data-lightbox", galleryID);

            // set gallery item index to link
            galleryItems[j].setAttribute("data-index", j);
          }
        }
      }
    }

    if (singleImages) {
      // loop over every single wp image block
      for (let k = 0; k < singleImages.length; k++) {
        let imageID = "image" + k;
        singleImages[k].setAttribute("data-lightbox", imageID);

        // set single image item index to link
        singleImages[k].setAttribute("data-index", k);
      }
    }

    if (wooGalleries) {
      // loop over every woo galleries
      for (let l = 0; l < wooGalleries.length; l++) {
        let wooGalleryID = "woogallery" + l;

        // find item links in gallery
        const wooGalleryItems = Array.from(
          wooGalleries[l].querySelectorAll(
            ".woocommerce-product-gallery__image a"
          )
        );

        if (wooGalleryItems) {
          // loop over gallery links in gallery
          for (let m = 0; m < wooGalleryItems.length; m++) {
            // set gallery id to link
            wooGalleryItems[m].setAttribute("data-lightbox", wooGalleryID);

            // set gallery item index to link
            wooGalleryItems[m].setAttribute("data-index", m);
          }
        }
      }
    }
  },

  initNav: function () {
    const htmlEl = document.querySelector("html");
    const bodyEl = document.querySelector("body");
    const btnMobileNav = document.querySelector("#btn-mobile-nav");

    if (btnMobileNav) {
      btnMobileNav.addEventListener("click", function () {
        if (htmlEl) {
          htmlEl.classList.toggle("nav-is-open");
        }

        if (bodyEl) {
          bodyEl.classList.toggle("nav-is-open");
        }

        btnMobileNav.innerText = htmlEl.classList.contains("nav-is-open")
          ? "Close"
          : "Menu";
      });
    }
  },

  initVideos: function () {
    fluidvids.init({
      selector: ["iframe", "object"], // runs querySelectorAll()
      players: ["www.youtube.com", "player.vimeo.com"], // players to support
    });

    // look for wp block cover with video
    const heroContainer = document.querySelector(".wp-block-cover");
    const heroVideo = document.querySelector(
      ".wp-block-cover__video-background"
    );

    // hero autoplay check
    if (heroVideo) {
      heroVideo.pause();
      heroVideo.play();

      // check if phone is in power saving mode
      heroVideo.addEventListener("suspend", () => {
        heroVideo.play();

        if (heroVideo.paused) {
          heroVideo.classList.add("video-disabled");

          if (heroContainer) {
            heroContainer.classList.add("container-video-disabled");
          }
        }
      });
    }
  },

  initPressFeed: function () {
    const pressFeedWrapper = document.getElementById("press-feed");

    // load press releases from melt water api
    if (pressFeedWrapper) {
      axios
        .get(
          "https://skrift-backend.solutions.meltwater.io/livecontent/5fdd951e5888d800192aa11c"
        )
        .then(function ({ data }) {
          pressFeedWrapper.innerText = "";
          const pressPosts = data.response;

          pressPosts.forEach((post) => {
            const article = document.createElement("article");
            article.innerHTML = `<h3>${post.title}</h3>${post.main_Body}`;
            pressFeedWrapper.insertAdjacentElement("afterbegin", article);
          });
        })
        .catch(function (error) {
          // handle error
          console.log(error);

          // clear loading text
          pressFeedWrapper.innerText = "";
        })
        .then(function () {
          // always executed
        });
    }
  },
};

app.init();
